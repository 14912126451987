import Main from "@/components/Main";
import "@/styles/globals.css";
import splitbee from "@splitbee/web";
import { createBrowserSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { AppProps } from "next/app";
import { Poppins } from "next/font/google";
import { useRouter } from "next/router";
import Script from "next/script";
import posthog from "posthog-js";
import { PostHogProvider, useFeatureFlagEnabled } from "posthog-js/react";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useLocalStorage } from "usehooks-ts";
import AuthRedirect from "../components/AuthRedirect";
import CustomTooltip from "../components/CustomTooltip";
import Dialog from "../components/Dialog";
import { DynamicHead } from "../components/DynamicHead";
import FreeTrialBanner from "../components/FreeTrialBanner";
import V2Banner from "../components/V2Banner";
import { ExportProvider } from "../context/exportContext";
import { initializeVideoElement } from "../helpers/video";
import { ModalContext, useModal } from "../hooks/useModal";

splitbee.init();

const sans = Poppins({
  subsets: ["latin"],
  variable: "--font-sans",
  weight: ["400", "500", "600", "700", "800", "900"],
});
const queryClient = new QueryClient();
// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host: "https://klap.app/ingest",
    autocapture: false,
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

export default function App({ Component, pageProps }: AppProps) {
  const modal = useModal();

  const [supabaseClient] = useState(() =>
    createBrowserSupabaseClient({
      cookieOptions: {
        domain: ".klap.app",
        maxAge: 365 * 24 * 60 * 60, // 1 year
        path: "/",
        sameSite: "None",
        secure: true,
      },
    })
  );
  const [trialCode, setTrialCode] = useLocalStorage("trial_code", "");
  const router = useRouter();

  useEffect(() => {
    if (router.query.tc) setTrialCode(router.query.tc as string);
    if (router.query.via) splitbee.track(`Referal Via ${router.query.via}`);
  }, [router.query]);

  const shouldDisplayV2Banner = useFeatureFlagEnabled("v2-banner-rollout");

  useEffect(() => {
    const initOneSignal = async () => {
      // await OneSignal.init({
      //   appId: "11b20d5d-2012-4674-9481-c923e3c01987",
      //   safari_web_id:
      //     "web.onesignal.auto.1ad4c923-47e5-493e-8349-b8f3b81ccfd9",
      //   // appId: "4f1a6838-0aee-449d-b40e-010dcdc99290",
      //   // safari_web_id:
      //   //   "web.onesignal.auto.56640081-e0ac-44c5-9d26-db4b2624f604",
      //   // notifyButton: {
      //   //   enable: true,
      //   // },
      //   // allowLocalhostAsSecureOrigin: true,
      // });
    };

    initOneSignal();
  }, []);

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    initializeVideoElement();
  }, []);

  useEffect(() => {
    supabaseClient.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
        const newPassword = prompt(
          "What would you like your new password to be?"
        );
        const { data, error } = await supabaseClient.auth.updateUser({
          password: newPassword ?? undefined,
        });

        if (data) alert("Password updated successfully!");
        if (error) alert("There was an error updating your password.");
      }
    });
  }, []);

  // const canAccessToV2 = useFeatureFlagEnabled("try-v2-banner");

  // useEffect(() => {
  //   if (canAccessToV2) {
  //     posthog.capture("Redirected to V2");
  //     // use browser native redirect
  //     window.location.href = "https://new.klap.app";
  //   }
  // }, [canAccessToV2]);

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${sans.style.fontFamily};
        }
      `}</style>
      <DynamicHead />
      <Script
        id="rewardful-inline"
        dangerouslySetInnerHTML={{
          __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`,
        }}
      />
      <Script
        id="rewardful"
        async
        src="https://r.wdfl.co/rw.js"
        data-rewardful="d3411c"
      />

      {/* <!-- Google tag (gtag.js) --> */}
      {/* <Script
        id="gtag-2"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-PJJ6WM9KBQ"
      />
      <Script
        id="gtag-inline-2"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'G-PJJ6WM9KBQ');`,
        }}
      /> */}

      {/* <!-- Google tag (gtag.js) --> */}
      <Script
        id="gtag"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-P6Q56PNDMH"
      />
      <Script
        id="gtag-inline"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'G-P6Q56PNDMH');`,
        }}
      />
      {/* Tiktok pixel code */}
      <Script
        id="tiktok-pixel"
        dangerouslySetInnerHTML={{
          __html: `
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
            
              ttq.load('CJRE13JC77U2Q32CC2R0');
              ttq.page();
            }(window, document, 'ttq');`,
        }}
      />

      {/* <!-- Meta Pixel Code --> */}
      <Script
        id="meta-pixel"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1966093087124184');
            fbq('track', 'PageView');`,
        }}
      />

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=1966093087124184&ev=PageView&noscript=1"
        />
      </noscript>

      {/* <!-- End Meta Pixel Code --> */}

      {/* <!-- X.com Pixel Code --> */}
      {/* <Script
          dangerouslySetInnerHTML={{
            __html: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            twq('config','ogfxl');`,
          }}
        /> */}
      {/* Intercom */}
      <Script
        id="intercom"
        dangerouslySetInnerHTML={{
          __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/o7y22sae';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
        }}
      />
      {/* Churn key */}
      <Script
        id="churnkey"
        dangerouslySetInnerHTML={{
          __html: `
              !function(){  
                if (!window.churnkey || !window.churnkey.created) {
                  window.churnkey = { created: true };
                  const a = document.createElement('script');
                  a.src = 'https://assets.churnkey.co/js/app.js?appId=agw7fh3fa';
                  a.async = true;
                  const b = document.getElementsByTagName('script')[0];
                  b.parentNode.insertBefore(a, b);
                }
              }();
            `,
        }}
      />

      {/* Linkedin Pixel
        <Script
          dangerouslySetInnerHTML={{
            __html: `

                    _linkedin_partner_id = "5516450";
                    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                    window._linkedin_data_partner_ids.push(_linkedin_partner_id);

                    (function(l) {
                        if (!l){
                            window.lintrk = function(a,b){ window.lintrk.q.push([a,b]) };
                            window.lintrk.q=[]
                        }
                        var s = document.getElementsByTagName("script")[0];
                        var b = document.createElement("script");
                        b.type = "text/javascript"; b.async = true;
                        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                        s.parentNode.insertBefore(b, s);
                    })(window.lintrk);
                `,
          }}
        /> */}

      {/*  OneSignal SDK */}
      {/* <Script
        id="onesignal"
        src="https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js"
        defer
      /> */}
      <QueryClientProvider client={queryClient}>
        <PostHogProvider client={posthog}>
          <SessionContextProvider
            supabaseClient={supabaseClient}
            initialSession={pageProps.initialSession}
          >
            <ModalContext.Provider value={modal}>
              <AuthRedirect>
                <ExportProvider>
                  <Main>
                    <V2Banner />
                    <FreeTrialBanner />
                    <Component {...pageProps} />
                    <CustomTooltip id="global" />
                    <Toaster containerClassName="!z-[99999]" />
                  </Main>
                  <Dialog
                    isOpen={modal.isOpen}
                    onClose={modal.close}
                    className={modal.className}
                  >
                    {modal.children}
                  </Dialog>
                </ExportProvider>
              </AuthRedirect>
            </ModalContext.Provider>
          </SessionContextProvider>
        </PostHogProvider>
      </QueryClientProvider>
    </>
  );
}
