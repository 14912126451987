import { useSessionContext } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { ComponentPropsWithoutRef, FC, useContext, useEffect } from "react";
import { ModalContext } from "../hooks/useModal";
import Info from "./Info";

type AuthRedirectProps = {} & ComponentPropsWithoutRef<"div">;

const publicRoutes = [
  "/",
  "/login",
  "/pricing",
  "/terms-of-services",
  "/privacy-policy",
  "/clip-download",
  "/affiliate",
  "/alternatives/*",
  "/briefs/*",
  "/tools/*",
  "/blog/*",
];

function extractMessageFromHash(hash: string): string | null {
  // Assuming the hash is provided as an argument or you can use window.location.hash directly
  // Remove the '#' and parse the remaining string as query parameters
  const params = new URLSearchParams(hash.substring(1)); // Remove '#' with substring

  // Extract the 'message' parameter
  let message = params.get("message");

  if (message === null) {
    message = params.get("error_description");
  }

  // If message is not null, decode URI component to handle special characters
  return message ? decodeURIComponent(message) : null;
}

const isPublicRoute = (route: string) => {
  return publicRoutes.some((publicRoute) => {
    if (publicRoute.endsWith("/*")) {
      // Check for wildcard match
      return route.startsWith(publicRoute.slice(0, -2));
    }
    return route === publicRoute;
  });
};

const AuthRedirect: FC<AuthRedirectProps> = ({ ...rest }) => {
  const { isLoading: sessionIsLoading, session } = useSessionContext();
  const router = useRouter();
  const posthog = usePostHog();
  const modal = useContext(ModalContext);

  const handleShowHashMessage = () => {
    const message = extractMessageFromHash(window.location.hash);

    if (message) {
      modal?.open(
        <Info
          description={message}
          onClickCta={() => {
            modal?.close();
            window.location.hash = "";
          }}
          ctaLabel="Got it"
        />
      );
    }
  };

  useEffect(() => {
    if (sessionIsLoading) return;
    if (!session && !isPublicRoute(router.route)) {
      router.push("/login");
    }

    handleShowHashMessage();
  }, [sessionIsLoading, session, router.route]);

  // useEffect(() => {
  //   if (sessionIsLoading) return;
  //   if (session && posthog) {
  //     // console.log("IDENTIFY");
  //     posthog.identify(session.user.id, {
  //       email: session.user.email,
  //       created_at: session.user.created_at,
  //     });
  //     // if user was created in the last 5 min
  //     if (
  //       new Date(session.user.created_at) > new Date(Date.now() - 5 * 60 * 1000)
  //     ) {
  //       posthog.capture("User Signed Up");
  //       posthog.updateEarlyAccessFeatureEnrollment("try-v2-banner", true);
  //       window.location.href = "https://new.klap.app";
  //     }
  //     posthog.onFeatureFlags(function () {
  //       // feature flags should be available at this point
  //       if (
  //         !window.location.host.includes("old") &&
  //         posthog.isFeatureEnabled("try-v2-banner")
  //       ) {
  //         window.location.href = "https://new.klap.app";
  //       }
  //     });
  //   }
  // }, [session?.access_token]);

  // useEffect(() => {
  //   posthog.onFeatureFlags(function () {
  //     if (posthog.isFeatureEnabled("try-v2-banner")) {
  //       window.location.href = "https://klap.app";
  //     }
  //   });
  // }, [posthog]);

  return (
    <div {...rest} className={`${rest.className}`}>
      {rest.children}
    </div>
  );
};

export default AuthRedirect;
