import { ComponentPropsWithoutRef, FC } from "react";
import Button from "./Button";
import Typography from "./Typography";

type ConfirmActionProps = {
  title?: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmLabel?: string;
  isLoading?: boolean;
} & ComponentPropsWithoutRef<"div">;

const ConfirmAction: FC<ConfirmActionProps> = ({
  title,
  description,
  onConfirm,
  onCancel,
  isLoading,
  confirmLabel,
  ...rest
}) => {
  return (
    <div {...rest} className={`${rest.className}`}>
      <Typography variant="h6" className="">
        {title ?? " Are you sure you want to delete this video?"}
      </Typography>

      <Typography variant="body2" className="mt-4 text-gray-600">
        {description ?? "This action cannot be undone"}
      </Typography>
      <div className="mt-8 flex flex-col-reverse md:flex-row gap-2">
        <Button
          label={"Cancel"}
          onClick={onCancel}
          size="m"
          variant="secondary"
        />
        <Button
          label={confirmLabel ?? "Delete"}
          onClick={onConfirm}
          size="m"
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default ConfirmAction;
