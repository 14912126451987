import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { ComponentPropsWithoutRef, FC } from "react";
import Button from "./Button";

type LoginButtonProps = {} & ComponentPropsWithoutRef<"div">;

const LoginButton: FC<LoginButtonProps> = ({ ...rest }) => {
  const user = useUser();
  const router = useRouter();
  const supabaseClient = useSupabaseClient();
  const posthog = usePostHog();

  const signOut = async () => {
    posthog?.reset();
    await supabaseClient.auth.signOut();
    router.push("/");
  };

  return (
    <div {...rest} className={`${rest.className}`}>
      {user ? (
        <Button
          size="m"
          onClick={signOut}
          variant="tertiary"
          label="Log out"
          className="w-full flex !justify-start hover:!bg-slate-100"
          leading={
            <ArrowRightOnRectangleIcon className="w-4 h-4 text-slate-500" />
          }
        />
      ) : (
        <Link href="/login">
          <Button
            size="m"
            onClick={() => {}}
            label="Log in"
            className="w-full"
          />
        </Link>
      )}
    </div>
  );
};

export default LoginButton;
