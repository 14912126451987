import { useFeatureFlagVariantKey } from "posthog-js/react";
import { ComponentPropsWithoutRef, FC, useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { PLANS } from "../const/planConst";
import MoneyBackGuarantee from "./MoneyBackGuarantee";
import PricingCards from "./PricingCards";
import Typography from "./Typography";

type NeedPaidPlanProps = {
  plan: keyof typeof PLANS;
  variant?: keyof typeof planToHeadings;
} & ComponentPropsWithoutRef<"section">;

const planToHeadings = {
  default: {
    free: {
      title: "Unlock Premium features",
      description: "Choose a plan to continue and unlock all features!",
    },
    basic: {
      title: "Unlock Pro features",
      description: "Choose a plan to continue and unlock Pro features!",
    },
    pro: {
      title: "Unlock Enterprise features",
      description:
        "You've reached the limit of the Pro plan. Contact us to upgrade to the Enterprise plan.",
    },
    // TODO: remove this, here for type issue
    enterprise: {
      title: "",
      description: "",
    },
  },
  too_long_for_current_plan: {
    free: {
      title: "Unlock longer videos",
      description:
        "Video too long for free trial. Choose a plan to upload videos up to 2 hours long.",
    },
    basic: {
      title: "Unlock longer videos",
      description:
        "Video too long for your plan (max. 45min). Upgrade to Pro to upload videos up to 2 hours long.",
    },
    pro: {
      title: "Unlock longer videos",
      description:
        "Video too long for your plan (max. 2h). Contact us to upgrade to the Enterprise plan.",
    },
    // TODO: remove this, here for type issue
    enterprise: {
      title: "",
      description: "",
    },
  },
  export_clip: {
    free: {
      title: "Upgrade to unlock export",
      description: "Choose a plan to continue and export your clips!",
    },
    basic: {
      title: "Upgrade to unlock export",
      description: "Choose a plan to continue and export your clips!",
    },
    pro: {
      title: "Upgrade to unlock export",
      description: "Choose a plan to continue and export your clips!",
    },
    // TODO: remove this, here for type issue
    enterprise: {
      title: "",
      description: "",
    },
  },
};

const NeedPaidPlan: FC<NeedPaidPlanProps> = ({ plan, variant, ...rest }) => {
  const _variant = variant ?? "default";
  // const featureFlagVariant = "discount"; //useFeatureFlagVariantKey("discount-yearly");

  // const [coupon, setCoupon] = useLocalStorage("coupon", "");

  // useEffect(() => {
  //   if (featureFlagVariant == "discount") setCoupon(`7wnHj9mK,${Date.now()}`);
  // }, [featureFlagVariant]);

  return (
    <section {...rest} className={`${rest.className} `}>
      <div className="flex-col flex justify-center content-center items-center text-center my-4">
        {/* <div className="inline-flex items-center justify-center gap-2 bg-slate-200 rounded-full px-4 py-1 mx-auto">
          {variant != "export_clip" && (
            <>
              <Typography
                variant="caption"
                className="text-slate-600 font-medium"
              >
                {profile?.rollingVideoCount ?? 0}{" "}
                <span className="text-slate-500">
                  / {trialCode ? "2 videos" : profile?.videoCountLimit}
                  {" video(s)"}
                  {plan == "basic" && "(free)"}
                </span>
              </Typography>
              {plan != "basic" && (
                <>
                  <div className="h-3 w-px bg-slate-400 rounded-full mx-1" />
                  <Typography
                    variant="caption"
                    className="text-slate-600 font-medium"
                  >
                    {profile?.rollingVideoCount ?? 0}{" "}
                    <span className="text-slate-500">
                      / {planData[_variant]["basic"].maxClip} clips
                    </span>
                  </Typography>
                </>
              )}
            </>
          )}
        </div> */}
        <Typography variant="h5" className="mt-8">
          {planToHeadings[_variant][plan].title}
        </Typography>

        <Typography variant="body1" className="mt-4 text-gray-600">
          {planToHeadings[_variant][plan].description}
        </Typography>

        {/* <Typography className="mt-8" variant="body1">
          Pro plan gives you access to the following features:
        </Typography> */}

        {/* <ul className="flex-1 self-start flex flex-col mt-8 gap-4 w-full">
          <li className="flex gap-2 items-center">
            <CheckCircleIcon className="w-5 h-5" />
            <Typography>Up to 1 hour long video</Typography>
          </li>
          <li className="flex gap-2 items-center">
            <CheckCircleIcon className="w-5 h-5" />
            <Typography>Unlimited videos</Typography>
          </li>
          <li className="flex gap-2 items-center">
            <CheckCircleIcon className="w-5 h-5" />
            <Typography>HD download</Typography>
          </li>
        </ul> */}
        {/* <div className="inline-flex justify-center items-center mt-12 text-purple-600">
          <span className="inline-flex justify-center items-center mr-1">
            <SparklesIcon className="w-4 h-4 inline-block mr-1" />
            <Typography className="  font-semibold" variant="caption">
              Special offer:
            </Typography>
          </span>
          <Typography className="font-medium" variant="caption">
            50% off on your first month!
          </Typography>
        </div>
        <Typography className="text-slate-600 mt-2" variant="overline">
          Only available now, once you leave this page the deal is gone.
        </Typography> */}
        <PricingCards className="mt-4 w-full" />
      </div>
    </section>
  );
};

export default NeedPaidPlan;
