import { VideoModel } from "../types/models";
import { WordTimecode } from "../types/player";
import { Database, Json } from "../types/supabase";
import { BBox } from "../types/tracking";

export const retroCompat = (
  videoVariant: Database["public"]["Tables"]["videos_variants"]["Row"]
) => {
  // Ensure that the cuts are in the correct format (retro compatibility)
  if (!videoVariant?.cuts) return videoVariant;
  videoVariant.cuts = (videoVariant?.cuts as Json[])?.map((cut: any) => {
    return { start: cut.start ?? cut[0], end: cut.end ?? cut[1] };
  });

  return videoVariant;
};

export const isVideoSchemaV1 = (
  video: Partial<Database["public"]["Tables"]["videos"]["Row"]>
) => {
  return video.status == "ready" && video.src_fps === null;
};

export const deserializeVideo = (
  videoData: Partial<Database["public"]["Tables"]["videos"]["Row"]>
): VideoModel => {
  // console.log("DESERIALIZE VIDEO", videoData);

  return {
    id: videoData.id ?? "",
    createdAt: videoData.created_at ?? "",
    updatedAt: videoData.updated_at ?? "",
    youtubeVid: videoData.youtube_vid ?? "",
    status: videoData.status ?? ("error" as any),
    authorId: videoData.author_id ?? "",
    detectedLanguage: videoData.detected_language ?? "en",
    translateTo: videoData.translate_to ?? null,
    // @ts-ignore
    duration: videoData.duration ?? 0,
    lowresSrcUrl: videoData.lowres_src_url ?? "",
    lowresSrcFps: videoData.lowres_src_fps ?? 0,
    lowresSrcFrameCount: videoData.lowres_src_frame_count ?? 0,
    srcUrl: videoData.src_url ?? "",
    srcFrameCount: videoData.src_frame_count ?? 0,
    srcFps: videoData.src_fps ?? 0,
    srcWidth: videoData.src_width ?? 0,
    srcHeight: videoData.src_height ?? 0,
    wordTimecodes: (videoData.word_timecodes ?? []) as WordTimecode[],
    errorCode: videoData.error_code ?? "",
    segments: videoData.segments as any,
    // @ts-ignore
    transcription: videoData.transcription,
    legacy: isVideoSchemaV1(videoData),
    metadata: {
      title: (videoData.metadata as any)?.title,
      description: (videoData.metadata as any)?.description,
    },
    name: videoData.name ?? "",
    // @ts-ignore
    cacheHit: videoData.cache_hit ?? false,
  };
};

let video: HTMLVideoElement | null = null;
const frameRequests: {
  src: string;
  frame: number;
  bbox?: BBox;
  ratio?: string;
  fps: number;
  resolve: (data: string) => void;
  reject: (reason: any) => void;
}[] = [];

export function initializeVideoElement() {
  video = document.createElement("video");
  video.style.display = "none";
  video.crossOrigin = "anonymous";
  document.body.appendChild(video);

  video.addEventListener("loadeddata", handleLoadedData);
}

function handleLoadedData() {
  if (!video) return;

  const request = frameRequests.shift()!;
  let [xRel, yRel, widthRel, heightRel] = request.bbox || [0, 0, 1, 1];

  if (request.ratio) {
    const [rw, rh] = request.ratio.split(":").map(Number);
    const originalAspect = widthRel / heightRel;
    const desiredAspect = rw / rh;

    if (desiredAspect > originalAspect) {
      // Width becomes dominant
      const newHeight = widthRel / desiredAspect;
      yRel += (heightRel - newHeight) / 2;
      heightRel = newHeight;
    } else {
      // Height becomes dominant
      const newWidth = heightRel * desiredAspect;
      xRel += (widthRel - newWidth) / 2;
      widthRel = newWidth;
    }
  }

  const xAbs = (video.videoWidth * xRel) / 2;
  const yAbs = (video.videoHeight * yRel) / 2;
  const widthAbs = video.videoWidth * widthRel;
  const heightAbs = video.videoHeight * heightRel;

  // Adjust canvas dimensions based on the requested ratio
  let canvasWidth, canvasHeight;

  if (request.ratio === "1:1") {
    canvasWidth = canvasHeight = Math.max(widthAbs, heightAbs);
  } else {
    canvasWidth = widthAbs;
    canvasHeight = heightAbs;
  }

  const canvas = document.createElement("canvas");
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  const ctx = canvas.getContext("2d");
  if (ctx) {
    // Draw the cropped image centered on the canvas
    ctx.drawImage(
      video,
      xAbs,
      yAbs,
      widthAbs,
      heightAbs,
      (canvasWidth - widthAbs) / 2,
      (canvasHeight - heightAbs) / 2,
      widthAbs,
      heightAbs
    );
  }

  request.resolve(canvas.toDataURL());
  processNextRequest();
}

function processNextRequest() {
  if (frameRequests.length === 0) return;

  const nextRequest = frameRequests[0];
  if (!video) return;

  video.src = nextRequest.src;
  if (!nextRequest.frame) return;
  if (!nextRequest.fps) return;
  video.currentTime = nextRequest.frame / nextRequest.fps; // Assuming 30 fps
}

export function requestFrame(
  src: string,
  frame: number,
  fps: number,
  bbox?: BBox,
  ratio?: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    frameRequests.push({ src, frame, bbox, ratio, fps, resolve, reject });
    if (frameRequests.length === 1) {
      processNextRequest();
    }
  });
}

const normalizeWordTimecodes = (wordTimecodes: WordTimecode[]) => {
  return repairNoDurationWordTimecodes(wordTimecodes);
};

const repairNoDurationWordTimecodes = (wordTimecodes: WordTimecode[]) => {
  // Using a constant for the magic numbers makes the code more maintainable
  const MAX_DURATION = 2.0;
  const MIN_DURATION = 0.1;
  const MAX_TIME_DIFF = 0.5;
  const ADJUST_DURATION = 0.2;
  const ADJUST_FACTOR = 0.1;

  return wordTimecodes.map((wordTimecode, i) => {
    const prevWordTimecode = i > 0 ? wordTimecodes[i - 1] : null;
    const nextWordTimecode =
      i < wordTimecodes.length - 1 ? wordTimecodes[i + 1] : null;

    const duration = wordTimecode.end - wordTimecode.start;
    if (prevWordTimecode) {
      const prevWordDuration = prevWordTimecode.end - prevWordTimecode.start;
      const prevWordTimeDiff = wordTimecode.start - prevWordTimecode.end;

      if (duration < MIN_DURATION && prevWordDuration >= ADJUST_DURATION) {
        prevWordTimecode.end -= ADJUST_FACTOR;
        wordTimecode.start = prevWordTimecode.end;
      }

      if (prevWordTimeDiff > 0 && prevWordTimeDiff < MAX_TIME_DIFF) {
        wordTimecode.start = prevWordTimecode.end;
      } else if (prevWordTimeDiff >= ADJUST_DURATION) {
        wordTimecode.start = wordTimecode.end - ADJUST_DURATION;
      }
    }

    if (nextWordTimecode) {
      const nextWordDuration = nextWordTimecode.end - nextWordTimecode.start;
      const nextWordTimeDiff = nextWordTimecode.start - wordTimecode.end;

      if (duration < MIN_DURATION && nextWordDuration >= ADJUST_DURATION) {
        nextWordTimecode.start += ADJUST_FACTOR;
        wordTimecode.end = nextWordTimecode.start;
      }

      if (nextWordTimeDiff > 0 && nextWordTimeDiff < MAX_TIME_DIFF) {
        wordTimecode.end = nextWordTimecode.start;
      } else if (nextWordTimeDiff >= ADJUST_DURATION) {
        wordTimecode.end = wordTimecode.start + ADJUST_DURATION;
      }
    }

    if (duration > MAX_DURATION) {
      wordTimecode.end = wordTimecode.start + MAX_DURATION;
    }

    return wordTimecode;
  });
};
