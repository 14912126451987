import { FC } from "react";
import { ITooltip, Tooltip } from "react-tooltip";

type CustomTooltipProps = {} & ITooltip;

const CustomTooltip: FC<CustomTooltipProps> = ({ ...rest }) => {
  return (
    <Tooltip
      delayShow={0}
      classNameArrow="invisible"
      className="!rounded-xl !bg-slate-900 !z-[9999]"
      id={`word-tooltip-insert-break`}
      {...rest}
    >
      {rest.children}
    </Tooltip>
  );
};

export default CustomTooltip;
