import React from "react";

type Props = {
  children: string;
};

const TextWithStrong: React.FC<Props> = ({ children }) => {
  // Function to process children string and return mixed content
  const processText = (text: string) => {
    const parts = text.split(/(\*\*[^\*]+\*\*)/g).filter((part) => part !== "");
    return parts.map((part, index) => {
      if (/\*\*([^\*]+)\*\*/.test(part)) {
        const textInside = part.replace(/^\*\*|\*\*$/g, ""); // Remove the surrounding "**"
        return <strong key={index}>{textInside}</strong>;
      }
      return part;
    });
  };

  return <>{processText(children)}</>;
};

export default TextWithStrong;
