import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ProfileModel } from "../types/models";
import { Database } from "../types/supabase";

export const useProfile = () => {
  const supabaseClient = useSupabaseClient<Database>();
  const queryClient = useQueryClient();
  const session = useSession();
  const userId = session?.user.id;
  const queryKey = "profile";

  const {
    isLoading,
    error,
    data: profile,
  }: {
    isLoading: boolean;
    error: any;
    data: ProfileModel | undefined;
  } = useQuery({
    enabled: !!userId,
    queryKey: [queryKey, userId],
    queryFn: async () => {
      if (!session?.user.id) return;
      return supabaseClient
        .from("profiles")
        .select("*")
        .eq("id", session.user.id)
        .single()
        .then((r) => {
          if (r.error) throw r.error;
          if (!r.data) throw new Error("Author's videos not found");
          return deserializeProfile(r.data);
        });
    },
  });

  const _updateProfile = async (
    update: Partial<Database["public"]["Tables"]["profiles"]["Row"]>
  ) => {
    const data = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/users/me`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session?.access_token}`,
      },
      body: JSON.stringify(update),
    }).then((res) => res.json());
    return data;
  };

  const { mutate: updateProfile } = useMutation({
    mutationFn: _updateProfile,
    onMutate: async (update) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [queryKey, userId],
      });

      // Snapshot the previous value
      const previousUpdate = queryClient.getQueryData([queryKey, userId]);

      // Optimistically update to the new value
      queryClient.setQueryData([queryKey, userId], (old: any) => {
        // console.log(update);
        return { ...old, ...update };
      });

      // Return a context object with the snapshotted value
      return { previousUpdate };
    },

    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([queryKey, userId], context?.previousUpdate);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey, userId] });
    },
  });

  return { isLoading, profile, updateProfile };
};

const deserializeProfile = (
  data: Database["public"]["Tables"]["profiles"]["Row"]
): ProfileModel => {
  return {
    id: data.id,
    email: data.email,
    avatarUrl: data.avatar_url,
    name: data.name,
    defaultPresetId: data.default_preset_id,
    customerId: data.customer_id,
    subscriptionStatus: data.subscription_status,
    rollingVideoCount: data.rolling_video_count,
    rollingClipCount: data.rolling_clip_count,
    plan: data.plan as ProfileModel["plan"],
    videoCountLimit: data.video_count_limit,
    // @ts-ignore
    clipCountLimit: data.clip_count_limit,
    uploadDurationAllowanceSeconds: data.upload_duration_allowance_seconds,
    onboardingSurveyAnswered: data.onboarding_survey_answered as string[],
    createdAt: new Date(data.created_at),
  };
};
