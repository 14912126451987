// import { useSession } from "@supabase/auth-helpers-react";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { useSession } from "@supabase/auth-helpers-react";
import Link from "next/link";
import { useState } from "react";
import Button from "./Button";
import ResourcesNav from "./ResourcesNav";
import ToolsNav from "./ToolsNav";
import UserNav from "./UserNav";

interface NavProps {
  className?: string;
}

export const DISCORD_LINK = "https://discord.gg/KcAcHdrSQU";

const Nav = (props: NavProps) => {
  const session = useSession();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <nav className={`${props.className}`}>
      <Button
        size="m"
        variant="tertiary"
        onClick={() => setShowMenu(!showMenu)}
        leading={<Bars3Icon className="w-5 h-5" />}
        className="md:hidden"
        aria-label="Menu"
      />
      <UserNav className="md:hidden" />
      <div
        className={`${
          showMenu ? "" : "hidden md:flex"
        } flex flex-col md:flex-row absolute left-0 right-0 items-start md:relative bg-slate-100 md:items-center px-3 md:px-0 top-full justify-end gap-4 text-sm font-normal whitespace-nowrap text-ellipsis w-full py-4 md:py-0`}
      >
        <ResourcesNav />
        <ToolsNav />
        <Link className="overflow-ellipsis overflow-hidden" href={"/pricing"}>
          <Button
            size="m"
            variant="tertiary"
            onClick={() => {}}
            label="Pricing"
            className="w-full"
          />
        </Link>

        {session && (
          <Link className="overflow-ellipsis overflow-hidden" href={"/exports"}>
            <Button
              size="m"
              variant="tertiary"
              onClick={() => {}}
              label="Exports"
              className="w-full"
            />
          </Link>
        )}
        {session && (
          <Link
            className="overflow-ellipsis overflow-hidden"
            href={"/dashboard"}
          >
            <Button
              size="m"
              variant="tertiary"
              onClick={() => {}}
              label="Dashboard"
              className="w-full"
            />
          </Link>
        )}

        <UserNav className="hidden md:flex" />
        {/* <a
        className="flex gap-1 items-center"
        href="mailto:githuntdev@gmail.com"
        rel="noreferrer"
        target={"_blank"}
      >
        <ArrowTopRightOnSquareIcon className="w-3 h-3" /> Contact
      </a> */}
      </div>
    </nav>
  );
};

export default Nav;
