import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { ComponentPropsWithoutRef, FC, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import Typography from "./Typography";

type LogoProps = {} & ComponentPropsWithoutRef<"div">;

const V1_DATA = {
  version: "1.0",
  isBeta: false,
  tooltip: "Go to Klap 2.0",
  href: "https://new.klap.app/",
};

const V2_DATA = {
  version: "2.0",
  isBeta: true,
  tooltip: "Go to Klap 1.0",
  href: "https://klap.app/",
};

const Logo: FC<LogoProps> = ({ ...rest }) => {
  // const canAccessV2 = useFeatureFlagEnabled("try-v2-banner");

  const [isV2, setIsV2] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsV2(
      window.location.hostname === "localhost" ||
        window.location.hostname === "new.klap.app"
    );
  }, []);
  const isOptInV2 = useFeatureFlagEnabled("try-v2-banner");

  const data = isV2 ? V2_DATA : V1_DATA;
  return (
    <div
      {...rest}
      className={`${rest.className} h-auto flex gap-2 items-center dark:fill-white fill-slate-950`}
    >
      {/* <ReactSVG className="w-7 h-7" src="/svg/logomark.svg" />
      <Typography
        className="flex-1 font-bold"
        variant="h5"
        component="subtitle1"
      >
        Klap
      </Typography> */}
      <ReactSVG className="w-20 h-auto shrink-0" src="/svg/Klap_logo.svg" />

      <div
        data-tooltip-content={data.tooltip}
        data-tooltip-id="global"
        onClick={() => {
          posthog.capture("Opted In V2");
          posthog.updateEarlyAccessFeatureEnrollment("try-v2-banner", true);
          window.location.href = "https://new.klap.app/";
        }}
      >
        <div className="text-xs bg-slate-200 text-slate-500 dark:text-slate-300 dark:bg-slate-800 px-2 rounded-full flex gap-1 items-center justify-center py-0.5">
          <Typography variant="overline" className="font-semibold leading-none">
            {data.version}
          </Typography>
          {data.isBeta && (
            <>
              <div className="w-px h-2 bg-slate-400" />
              <Typography
                variant="overline"
                className="text-slate-500 dark:text-slate-400"
              >
                Beta
              </Typography>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Logo;
