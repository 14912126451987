import { SparklesIcon } from "@heroicons/react/24/solid";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { ComponentPropsWithoutRef, FC, useState } from "react";
import { DISCORD_LINK } from "./Nav";
import PricingCard from "./PricingCard";
import PricingTabs from "./PricingTabs";
import Typography from "./Typography";

const SpecialOffer = () => {
  return (
    <div>
      <div className="flex  items-center justify-center text-purple-600">
        <span className="inline-flex justify-center items-center mr-1">
          <SparklesIcon className="w-4 h-4 inline-block mr-1" />
          <Typography className="font-semibold" variant="subtitle1">
            Special offer:
          </Typography>
        </span>
        <Typography className="font-medium" variant="subtitle1">
          30% off the first month!
        </Typography>
      </div>
      <Typography className="text-slate-600 mt-2 mb-4 block" variant="caption">
        Only available now, once you leave this page the deal is gone.
      </Typography>
    </div>
  );
};
const YearlyOffer = () => {
  return (
    <div>
      <div className="flex  items-center justify-center text-purple-600">
        <span className="inline-flex justify-center items-center mr-1">
          <SparklesIcon className="w-4 h-4 inline-block mr-1" />
          <Typography className="font-semibold" variant="subtitle1">
            Special offer:
          </Typography>
        </span>
        <Typography className="font-medium" variant="subtitle1">
          30% off the whole year!
        </Typography>
      </div>
      <Typography className="text-slate-600 mt-2 mb-4 block" variant="caption">
        Only available now, once you leave this page the deal is gone.
      </Typography>
    </div>
  );
};

type PricingCardsProps = {} & ComponentPropsWithoutRef<"div">;

const PricingCards: FC<PricingCardsProps> = ({ ...rest }) => {
  // const [coupon, setCoupon] = useLocalStorage("coupon", "");
  const [selected, setSelected] = useState<"monthly" | "yearly">("yearly");

  return (
    <div
      {...rest}
      className={`${rest.className} ${"max-w-5xl"} mx-auto px-3 text-center`}
    >
      <PricingTabs
        selected={selected}
        onSelect={(value) => setSelected(value as "monthly" | "yearly")}
        className="mx-auto mb-8"
      />
      {/* {coupon && selected == "yearly" && <YearlyOffer />} */}
      <div className="flex flex-col md:flex-row gap-8 justify-center">
        <PricingCard
          className="flex-1"
          price={selected == "monthly" ? 29 : parseFloat((29 * 0.8).toFixed(1))}
          features={[
            // "10 YouTube videos (~ 10 shorts each)",
            "Upload 10 videos monthly",
            "Up to 45 minutes long videos",
            "Generate 100 clips monthly",
            "HD download",
          ]}
          plan={selected == "monthly" ? "basic" : "basic-yearly"}
        />
        {/* <PricingCard
           className="flex-1"
           price={29}
           title="$29/month"
           description="For creators"
           features={[
             "Generate 100 clips",
             "Up to 45 minutes long videos",
             "HD download",
           ]}
           plan="basic"
         /> */}
        <PricingCard
          className="flex-1"
          price={selected == "monthly" ? 79 : parseFloat((79 * 0.8).toFixed(1))}
          features={[
            "Upload 30 videos monthly",
            "Up to 2 hours long videos",
            "Generate 300 clips monthly",
            "4K download",
          ]}
          plan={selected == "monthly" ? "pro" : "pro-yearly"}
        />

        <PricingCard
          className="flex-1"
          price={
            selected == "monthly" ? 189 : parseFloat((189 * 0.8).toFixed(1))
          }
          features={[
            "Upload 100 videos monthly",
            "Up to 3 hours long videos",
            "Generate 1000 clips monthly",
            "4K download",
          ]}
          plan={selected == "monthly" ? "pro-plus" : "pro-plus-yearly"}
        />
      </div>
      <Typography className="mt-8 text-slate-700" variant="caption">
        Need more?{" "}
        <a className="text-slate-500" href={DISCORD_LINK}>
          {`Let's talk!`}
        </a>
      </Typography>
    </div>
  );
};

export default PricingCards;
