import { createContext, ReactElement, useState } from "react";

type ModalOpenOptions = {
  className?: string;
};

type ModalContextProps = {
  isOpen: boolean;
  open: (children: ReactElement, options?: ModalOpenOptions) => void;
  close: () => void;
  children: ReactElement | null;
  className: string;
};

export const ModalContext = createContext<ModalContextProps>(
  {} as ModalContextProps
);

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [children, setChildren] = useState<ReactElement | null>(null);
  const [className, setClassName] = useState<string>("");

  const open = (children: ReactElement, options?: ModalOpenOptions) => {
    if (options?.className) setClassName(options.className);
    setIsOpen(true);
    setChildren(children);
  };

  const close = () => {
    setIsOpen(false);
    setTimeout(() => setChildren(null), 300);
  };

  return {
    open,
    close,
    isOpen,
    children,
    className,
  };
};
