import Head from "next/head";
import { useRouter } from "next/router";
import { SITE_DESC, SITE_TITLE } from "../const/webConst";

type DynamicHeadProps = {
  title?: string;
  description?: string;
  image_url?: string;
  favicon_url?: string;
};

export const DynamicHead = (props: DynamicHeadProps) => {
  const { asPath } = useRouter();
  const canonical = (process.env.NEXT_PUBLIC_WEB_URL + asPath).split("?")[0];

  return (
    <Head key="meta-tags">
      {/* Primary Meta Tags */}
      <title>{props.title}</title>
      <meta name="title" content={props.title} key="title" />
      <meta name="description" content={props.description} key="description" />
      <link rel="icon" href={props.favicon_url} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`${process.env.NEXT_PUBLIC_WEB_URL}${asPath}`}
      />
      <meta property="og:title" content={props.title} key="og-title" />
      <meta
        property="og:description"
        content={props.description}
        key="og-description"
      />
      <meta property="og:image" content={props.image_url} key="og-image" />
      <meta property="og:site_name" content={"Klap"} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:url"
        content={`${process.env.NEXT_PUBLIC_WEB_URL}${asPath}`}
      />
      <meta name="twitter:title" content={props.title} key="tw-title" />
      <meta
        name="twitter:description"
        content={props.description}
        key="tw-description"
      />
      <meta name="twitter:image" content={props.image_url} key="tw-image" />

      {/* Canonical */}
      <link rel="canonical" href={canonical} key="canonical" />
    </Head>
  );
};

DynamicHead.defaultProps = {
  title: `Klap | ${SITE_TITLE}`,
  description: SITE_DESC,
  image_url: `${process.env.NEXT_PUBLIC_WEB_URL}/images/og.jpg`,
  favicon_url: `/favicon.ico`,
};
