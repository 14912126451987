import { useFeatureFlagVariantKey } from "posthog-js/react";
import { ComponentPropsWithoutRef, FC } from "react";
import { useLocalStorage } from "usehooks-ts";
import Button from "./Button";
import Typography from "./Typography";

type PricingTabsProps = {
  onSelect: (value: "monthly" | "yearly") => void;
  selected: "monthly" | "yearly";
} & ComponentPropsWithoutRef<"div">;

const PricingTabs: FC<PricingTabsProps> = ({ onSelect, selected, ...rest }) => {
  const handleClick = (value: "monthly" | "yearly") => {
    onSelect(value);
  };
  // const yearlyDiscountWordingVariant = useFeatureFlagVariantKey(
  //   "yearly-discount-wording"
  // );

  const discoutLabel = {
    control: "20% off",
    "month-free": "2 months free",
    "save-dollar": "Save $190",
  };
  return (
    <div
      {...rest}
      className={`${rest.className} inline-flex gap-2 items-center justify-center bg-slate-200 p-1 rounded-2xl mx-auto`}
    >
      <Button
        variant={selected == "monthly" ? "secondary" : "tertiary"}
        size="l"
        onClick={() => handleClick("monthly")}
        label="Monthly"
      />
      <Button
        variant={selected == "yearly" ? "secondary" : "tertiary"}
        size="l"
        onClick={() => handleClick("yearly")}
        label="Yearly"
        trailing={
          <Typography
            variant="overline"
            className={`rounded-full px-2 py-0.5 font-medium bg-green-300 text-green-950`}
          >
            {/* {yearlyDiscountWordingVariant
              ? discoutLabel[
                  yearlyDiscountWordingVariant as keyof typeof discoutLabel
                ]
              : "20% off"} */}
            {"Save $190"}
          </Typography>
        }
      />
    </div>
  );
};

export default PricingTabs;
