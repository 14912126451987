import { XMarkIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import Modal from "react-modal";
import Button from "./Button";

type DialogProps = {
  onClose: () => void;
} & Modal.Props;

const Dialog: FC<DialogProps> = ({ onClose, ...rest }) => {
  return (
    <Modal
      {...rest}
      // ref={ref}
      // onClick={(e) => {
      //   if (e.target === ref.current) {
      //     ref.current?.close();
      //   }
      // }}

      overlayClassName={`bg-slate-500/50 fixed inset-0 z-[9999] flex items-center justify-center p-3`}
      onRequestClose={onClose}
      className={`${rest.className} relative bg-white overflow-auto max-h-full p-0 border-0 rounded-2xl backdrop:bg-slate-500/50 max-w-2xl sm:min-w-0 sm:w-[calc(100%-theme(spacing.6))] min-w-full`}
    >
      <Button
        variant="tertiary"
        size="m"
        onClick={onClose}
        className="absolute top-1.5 right-1.5 sm:hidden"
        leading={<XMarkIcon className="w-6 h-6 text-slate-500" />}
      />

      <div className="p-6">{rest.children}</div>
    </Modal>
  );
};

export default Dialog;
