import {
  ComponentPropsWithoutRef,
  forwardRef,
  LegacyRef,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from "react";

import Spinner from "./Spinner";
import Typography, { TypeScale } from "./Typography";

export type Size = {
  xs: "xs";
  s: "s";
  l: "l";
  xl: "xl";
  m: "m";
};

export type Variant = {
  primary: "primary";
  secondary: "secondary";
  tertiary: "tertiary";
  danger: "danger";
};

export const useDotEllipsisAnimation = (speed = 500): string => {
  const [ellipsis, setEllipsis] = useState<string>(".");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setEllipsis((prevEllipsis) =>
        prevEllipsis === "..." ? "." : prevEllipsis + "."
      );
    }, speed);

    return () => clearInterval(intervalId);
  }, [speed]);

  return ellipsis;
};

export type ButtonProps = {
  leading?: ReactNode;
  trailing?: ReactNode;
  loading?: boolean;
  loadingProgress?: number;
  loadingLabel?: string;
  label?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  onMouseUp?: MouseEventHandler<HTMLButtonElement>;
  size?: Size["l"] | Size["m"] | Size["s"];
  variant?:
    | Variant["primary"]
    | Variant["secondary"]
    | Variant["tertiary"]
    | Variant["danger"];
  className?: string;
  style?: any;
  disabled?: boolean;
} & ComponentPropsWithoutRef<"button">;
const Button = forwardRef(
  (
    {
      leading,
      trailing,
      loading,
      loadingProgress,
      loadingLabel,
      label,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      onMouseDown,
      size = "l",
      variant = "primary",
      className,
      style,
      disabled,
      ...rest
    }: ButtonProps,
    ref?: LegacyRef<HTMLButtonElement>
  ) => {
    label = label ?? loadingLabel;

    const _size: { [key in keyof Partial<Size>]: HTMLElement["className"] } = {
      s: `${label ? "px-3 py-1.5" : "w-8"} h-8`,
      m: `${label ? "px-4 py-2" : "w-10"} h-10`,
      l: `${label ? "px-6 py-3" : "w-12"} h-12`,
    };

    const _variant: {
      [key in keyof Partial<Variant>]: HTMLElement["className"];
    } = {
      primary:
        "bg-slate-950 border-slate-950 text-white fill-white hover:bg-slate-900 duration-100 dark:bg-white dark:text-slate-950 dark:hover:bg-slate-200",
      secondary:
        "border border-slate-200 font-semibold text-slate-950 hover:bg-opacity-80 bg-white dark:bg-slate-950 dark:border-transparent dark:text-white dark:hover:bg-opacity-80 dark:hover:bg-slate-900",
      danger: "bg-white border-red-500 text-red-500 hover:bg-opacity-80",
      tertiary:
        "text-slate-950 border-white/0 bg-transparant hover:bg-slate-200 dark:text-white dark:border-transparent dark:hover:bg-slate-900",
    };

    const _typographyVariant: { [key in keyof Partial<Size>]: TypeScale } = {
      s: "button2",
      m: "button1",
      l: "button1",
    };

    const ellipsis = useDotEllipsisAnimation();
    return (
      <button
        style={style}
        ref={ref}
        className={`${className} ${_size[size]} ${_variant[variant]} ${
          !className?.includes("absolute") ? " relative" : ""
        } flex items-center justify-center rounded-xl border will-change-transform overflow-hidden disabled:opacity-50 disabled:pointer-events-none select-none disabled:cursor-default shrink-0`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        disabled={disabled}
        {...rest}
      >
        {loadingProgress !== undefined && (
          <div
            style={{ width: loadingProgress + "%" }}
            className={`absolute -z-10 inset-0 bg-slate-800 transition-all`}
          />
        )}
        <div
          className={`items-center justify-center flex gap-2 ${
            loading
              ? `opacity-1 visible ${loadingLabel ? "" : "absolute inset-0"}`
              : "opacity-0 invisible hidden"
          }`}
        >
          <Spinner />
          {loadingLabel && (
            <Typography variant={_typographyVariant[size]} className="mx-auto">
              <span>{loadingLabel}</span>
              <span className="w-0 inline-block overflow-visible">
                <span className="overflow-visible">{ellipsis}</span>
              </span>
            </Typography>
          )}
        </div>

        <div
          className={`flex gap-2 items-center justify-center ${
            loading
              ? `opacity-0 invisible ${loadingLabel ? " hidden" : ""}`
              : "opacity-1 visible"
          }`}
        >
          {leading && <>{leading}</>}
          {label && (
            <Typography
              variant={_typographyVariant[size]}
              className="whitespace-nowrap truncate"
            >
              {label}
            </Typography>
          )}
          {trailing && <>{trailing}</>}
        </div>
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
