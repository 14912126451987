import { ComponentPropsWithoutRef, FC } from "react";
import Button from "./Button";
import Typography from "./Typography";

type InfoProps = {
  title?: string;
  description: string;
  ctaLabel?: string;
  onClickCta: () => void;
} & ComponentPropsWithoutRef<"div">;

const Info: FC<InfoProps> = ({
  title,
  description,
  ctaLabel,
  onClickCta,
  ...rest
}) => {
  return (
    <div {...rest} className={`${rest.className}`}>
      {title && (
        <Typography variant="h5" className="mb-4">
          {title}
        </Typography>
      )}
      <Typography variant="body1" className="text-gray-600">
        {description}
      </Typography>
      <Button className="mt-8" label={ctaLabel} onClick={onClickCta} size="l" />
    </div>
  );
};

export default Info;
