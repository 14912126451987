import {
  ChartBarIcon,
  CreditCardIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useSession } from "@supabase/auth-helpers-react";
import Link from "next/link";
import { ComponentPropsWithoutRef, FC, useState } from "react";
import { Popover } from "react-tiny-popover";
import { useProfile } from "../hooks/useProfile";
import Avatar from "./Avatar";
import Button from "./Button";
import LoginButton from "./LoginButton";

type UserNavProps = {} & ComponentPropsWithoutRef<"div">;

const UserNav: FC<UserNavProps> = ({ ...rest }) => {
  const [open, setOpen] = useState(false);
  const session = useSession();
  const { profile, isLoading } = useProfile();
  return (
    <div {...rest} className={`${rest.className}`}>
      {session ? (
        <Popover
          containerClassName="bg-white rounded-2xl shadow-2xl p-1.5 flex flex-col gap-0 w-48 border border-slate-200 z-50"
          clickOutsideCapture
          onClickOutside={() => {
            setOpen(false);
          }}
          positions={["bottom"]}
          padding={10}
          boundaryInset={10}
          isOpen={open}
          content={
            <>
              <Link
                className="overflow-ellipsis overflow-hidden"
                href={"/account"}
              >
                <Button
                  size="m"
                  variant="tertiary"
                  onClick={() => {}}
                  label="Account"
                  className="w-full flex !justify-start hover:!bg-slate-100"
                  leading={<UserIcon className="w-4 h-4 text-slate-500" />}
                />
              </Link>
              <Link
                className="overflow-ellipsis overflow-hidden"
                href={"/usage"}
              >
                <Button
                  size="m"
                  variant="tertiary"
                  onClick={() => {}}
                  label="Usage"
                  className="w-full flex !justify-start hover:!bg-slate-100"
                  leading={<ChartBarIcon className="w-4 h-4 text-slate-500" />}
                />
              </Link>
              <Link
                className="overflow-ellipsis overflow-hidden"
                href={"/billing"}
              >
                <Button
                  size="m"
                  variant="tertiary"
                  onClick={() => {}}
                  label="Billing"
                  className="w-full flex !justify-start hover:!bg-slate-100"
                  leading={
                    <CreditCardIcon className="w-4 h-4 text-slate-500" />
                  }
                />
              </Link>
              <LoginButton />
            </>
          }
        >
          <Button
            variant="tertiary"
            size="m"
            onClick={() => {
              setOpen(!open);
            }}
            className="!p-1"
            leading={
              <Avatar
                className="w-7 h-7 !text-slate-500 !bg-slate-300"
                src={profile?.avatarUrl}
              />
            }
          />
        </Popover>
      ) : (
        <LoginButton />
      )}
    </div>
  );
};

export default UserNav;
