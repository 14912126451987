import { FaceSmileIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import { ComponentPropsWithoutRef, FC } from "react";

type AvatarProps = {
  src?: any;
} & ComponentPropsWithoutRef<"div">;

const Avatar: FC<AvatarProps> = ({ src, ...rest }) => {
  return (
    <div
      {...rest}
      className={`${rest.className} rounded-full overflow-hidden flex items-center justify-center bg-slate-200 text-slate-500 dark:bg-white/20 dark:text-white box-content`}
    >
      {src ? (
        <Image
          blurDataURL="/images/placeholder.png"
          placeholder="blur"
          width={128}
          height={128}
          src={src}
          alt={"Avatar"}
        />
      ) : (
        <FaceSmileIcon className="w-4 h-4" />
      )}
    </div>
  );
};

export default Avatar;
