import { ArrowUpRightIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { ComponentPropsWithoutRef, FC, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import Button from "./Button";
import { DISCORD_LINK } from "./Nav";

type ResourcesNavProps = {} & ComponentPropsWithoutRef<"div">;

const ResourcesNav: FC<ResourcesNavProps> = ({ ...rest }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpen(false));

  const DEV_RESOURCES_NAV_ITEMS = [
    {
      label: "API Docs",
      href: "https://docs.klap.app/",
    },
  ];
  const CONTENT_RESOURCES_NAV_ITEMS = [
    {
      label: "Blog",
      href: "/blog",
    },
  ];

  const SOCIAL_RESOURCES_NAV_ITEMS = [
    {
      label: "Discord",
      href: DISCORD_LINK,
      trailing: <ArrowUpRightIcon className="w-4 h-4" />,
    },
  ];

  const PARTNERSHIP_RESOURCES_NAV_ITEMS = [
    {
      label: "Affiliate",
      href: "/affiliate",
    },
  ];

  return (
    <div {...rest} className={`${rest.className} relative group`}>
      <div
        ref={ref}
        className={`bg-white rounded-2xl shadow-2xl p-1.5 flex-col w-48 border border-slate-200 z-50 absolute top-full mt-2 -left-1.5 ${
          open ? "" : "hidden"
        }`}
      >
        <ul>
          {CONTENT_RESOURCES_NAV_ITEMS.map((item) => (
            <li key={item.label}>
              <Link href={item.href}>
                <Button
                  onClick={() => {}}
                  label={item.label}
                  variant="tertiary"
                  className="w-full flex !justify-start hover:!bg-slate-100"
                  size="m"
                />
              </Link>
            </li>
          ))}
        </ul>
        <hr className="h-px bg-slate-200 border-transparent mx-4 rounded-full my-1.5" />
        <ul>
          {DEV_RESOURCES_NAV_ITEMS.map((item) => (
            <li key={item.label}>
              <Link href={item.href}>
                <Button
                  onClick={() => {}}
                  label={item.label}
                  variant="tertiary"
                  className="w-full flex !justify-start hover:!bg-slate-100"
                  size="m"
                />
              </Link>
            </li>
          ))}
        </ul>
        <hr className="h-px bg-slate-200 border-transparent mx-4 rounded-full my-1.5" />
        <ul>
          {PARTNERSHIP_RESOURCES_NAV_ITEMS.map((item) => (
            <li key={item.label}>
              <Link href={item.href}>
                <Button
                  onClick={() => {}}
                  label={item.label}
                  variant="tertiary"
                  className="w-full flex !justify-start hover:!bg-slate-100"
                  size="m"
                />
              </Link>
            </li>
          ))}
        </ul>
        <hr className="h-px bg-slate-200 border-transparent mx-4 rounded-full my-1.5" />
        <ul>
          {SOCIAL_RESOURCES_NAV_ITEMS.map((item) => (
            <li key={item.label}>
              <a href={item.href} target="_blank" rel="noreferrer">
                <Button
                  onClick={() => {}}
                  label={item.label}
                  variant="tertiary"
                  className="!w-full flex !justify-start hover:!bg-slate-100 flex-1"
                  size="m"
                  trailing={item.trailing}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <Button
        onClick={() => setOpen(true)}
        label="Resources"
        variant="tertiary"
        size="m"
        trailing={<ChevronDownIcon className="w-4 h-4 text-slate-500" />}
      />
    </div>
  );
};

export default ResourcesNav;
