import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  ExclamationTriangleIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import posthog from "posthog-js";
import { ComponentPropsWithoutRef, FC, cloneElement, useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import Button from "./Button";
import Typography from "./Typography";

const V1_DATA = {
  title: "Klap 2.0 is here!",
  description:
    "Experience enhanced outputs and a sleeker editing experience with Klap 2.0. We're perfecting it – you can switch back to V1 anytime.",
  buttonText: "Try Klap 2.0 Now!",
  buttonHref: "https://new.klap.app/",
  icon: <SparklesIcon />,
};

const V1_DATA_OLD = {
  title: "You are using an outdated version of Klap",
  description:
    "This version is no longer maintained and will be discontinued soon. Please switch to the latest version.",
  buttonText: "Go to Klap 2.0",
  buttonHref: "https://new.klap.app/",
  icon: <ExclamationTriangleIcon />,
};

const V2_DATA = {
  title: "Any trouble with Klap 2.0?",
  description:
    "We're perfecting Klap 2.0. If you're facing any issues, you can always switch back to V1.",
  buttonText: "Switch to V1",
  buttonHref: "https://klap.app/",
  icon: <ExclamationTriangleIcon />,
};

type FreeTrialBannerProps = {} & ComponentPropsWithoutRef<"div">;

const FreeTrialBanner: FC<FreeTrialBannerProps> = ({ ...rest }) => {
  const [showBanner, setShowBanner] = useLocalStorage("showBanner", true);

  const bannerData = V1_DATA_OLD;

  // useEffect(() => {
  //   setIsV2(
  //     window.location.hostname === "localhost" ||
  //       window.location.hostname === "new.klap.app"
  //   );
  // }, []);

  // if domain include old.
  // useEffect(() => {
  //   if (window.location.host.includes("old")) {
  //     setBannerData(V1_DATA_OLD);
  //   }
  //   setShowBanner(true);
  // }, []);
  // const canAccessV2 = useFeatureFlagEnabled("try-v2-banner");

  const handleClickClose = () => {
    setShowBanner(false);
  };

  if (!showBanner) return <></>;

  return (
    <div
      {...rest}
      className={`${rest.className} pl-3 pr-1 py-2 bg-slate-950 text-white border-b border-slate-800`}
    >
      <div className="flex items-center gap-3">
        <div className="w-6 h-6 bg-slate-900 rounded-md flex items-center justify-center shadow-md ring-2 border border-slate-800 ring-slate-700 shadow-slate-800 bg-gradient-to-tr from-slate-900 to-slate-700">
          {cloneElement(bannerData.icon, {
            className: "w-4 h-4 text-slate-300",
          })}
        </div>

        <div className="flex items-baseline justify-start gap-2">
          <Typography variant="subtitle3">{bannerData.title}</Typography>
          <Typography variant="overline" className="mt-0.5 text-slate-500">
            {/* Explore enhanced outputs and a sleeker editing experience with Klap
            2.0 Beta. While we perfect it, you can always revert to V1. */}
            {bannerData.description}
          </Typography>
          {/* <Typography variant="caption" className="mt-0.5 text-slate-500">
            Discover enhanced outputs and an improved editing experience with
            Klap 2.0, now in beta for all users without extra charge. Bear with
            us as we iron out any bugs. Switching back to V1 is always an
            option.
          </Typography> */}
        </div>
        <div className="ml-auto flex gap-1 ">
          {/* <a className="ml-auto" href={data.buttonHref}> */}
          <Button
            size="s"
            variant="secondary"
            label={bannerData.buttonText}
            onClick={() => {
              posthog.capture("Opted In V2");
              posthog.updateEarlyAccessFeatureEnrollment("try-v2-banner", true);
              window.location.href = "https://new.klap.app/";

              // if (isV2) localStorage.setItem("hasSwitchedToV1", "true");
            }}
          />
          {/* </a>  */}
          <Button
            size="s"
            onClick={handleClickClose}
            leading={<XMarkIcon className="w-4 h-4" />}
          />
        </div>
      </div>
    </div>
  );
};

export default FreeTrialBanner;
