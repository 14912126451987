import React, {
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
} from "react";
import TextWithStrong from "./TextWithStrong";

export type TypeScale =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "subtitle3"
  | "body"
  | "body1"
  | "body2"
  | "button1"
  | "button2"
  | "caption"
  | "overline";

interface Typography {
  variant?: TypeScale;
  component?: TypeScale;
  children?: ReactNode | string;
  style?: CSSProperties;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const _className: {
  [key: string]: HTMLAttributes<HTMLElement>["className"];
} = {
  h1: "text-6xl md:text-7xl font-semibold font-display [&>*]:font-semibold [&>strong]:text-violet-500",
  h2: "text-5xl md:text-6xl font-semibold font-display [&>*]:font-semibold [&>strong]:text-violet-500",
  h3: "text-4xl md:text-5xl font-semibold font-display [&>*]:font-semibold [&>strong]:text-violet-500",
  h4: "text-2xl md:text-3xl font-semibold font-display [&>*]:font-semibold [&>strong]:text-violet-500",
  h5: "text-xl md:text-2xl font-semibold font-display [&>*]:font-semibold [&>strong]:text-violet-500",
  h6: "text-l md:text-xl font-semibold font-display [&>*]:font-semibold [&>strong]:text-violet-500",
  subtitle1: "text-lg font-medium  md:leading-7 font-display [&>*]:font-medium",
  subtitle2: "text-base font-medium font-display [&>*]:font-medium",
  subtitle3: "text-sm font-medium font-display [&>*]:font-medium",
  body: `text-xl font-sans font-display font-normal [&>*]:font-normal`,
  body1: `text-lg font-sans font-display font-normal [&>*]:font-normal`,
  body2: `text-base font-normal font-sans [&>*]:font-normal`,
  button1: "text-sm font-semibold font-display [&>*]:font-semibold",
  button2: "text-xs font-semibold font-display [&>*]:font-semibold",
  caption: `text-sm font-normal font-display [&>*]:font-normal`,
  overline: "text-xs font-normal font-sans [&>*]:font-normal",
};

export default function Typography({
  variant = "body1",
  component = variant,
  children,
  style,
  className,
  onClick,
}: Typography) {
  const _props: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > = {
    style,
    className: `${_className[variant]} ${className} font-display`,
    onClick,
  };

  if (typeof children === "string" && children.includes("**"))
    children = <TextWithStrong>{children}</TextWithStrong>;

  switch (component) {
    case "h1":
      return <h1 {..._props}>{children}</h1>;
    case "h2":
      return <h2 {..._props}>{children}</h2>;
    case "h3":
      return <h3 {..._props}>{children}</h3>;
    case "h4":
      return <h4 {..._props}>{children}</h4>;
    case "h5":
      return <h5 {..._props}>{children}</h5>;
    case "h6":
      return <h6 {..._props}>{children}</h6>;
    case "subtitle1":
      return <span {..._props}>{children}</span>;
    case "subtitle2":
      return <span {..._props}>{children}</span>;
    case "subtitle3":
      return <span {..._props}>{children}</span>;
    case "body":
      return <p {..._props}>{children}</p>;
    case "body1":
      return <p {..._props}>{children}</p>;
    case "body2":
      return <p {..._props}>{children}</p>;
    case "button1":
      return <span {..._props}>{children}</span>;
    case "button2":
      return <span {..._props}>{children}</span>;
    case "caption":
      return <p {..._props}>{children}</p>;
    case "overline":
      return <span {..._props}>{children}</span>;

    default:
      return <></>;
  }
}
