export function convertSecondsToMinSec(seconds: number) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var sec = Math.round(seconds % 60);

  if (sec === 60) {
    minutes++;
    sec = 0;
  }

  if (minutes === 60) {
    hours++;
    minutes = 0;
  }

  var result = "";

  if (hours > 0) {
    result += (hours < 10 ? "0" : "") + hours + ":";
  }

  result +=
    (minutes < 10 ? "0" : "") + minutes + ":" + (sec < 10 ? "0" : "") + sec;

  return result;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertHTMLTagsToEntities(input: string): string {
  // Using a regular expression to match any HTML tags
  const regex = /<[^>]*>/g;

  // Replacing each HTML tag with its corresponding HTML entities
  const result = input.replace(regex, (match) => {
    return match
      .split("")
      .map((char) => {
        switch (char) {
          case "<":
            return "&lt;";
          case ">":
            return "&gt;";
          case "&":
            return "&amp;";
          case '"':
            return "&quot;";
          case "'":
            return "&#39;";
          default:
            return char;
        }
      })
      .join("");
  });

  return result;
}

export const printTimeLiteral = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const sec = Math.round(seconds % 60);

  if (minutes === 0) return `${sec}s`;
  else return `${minutes} minute${minutes > 1 ? "s" : ""}`;
};

export function extractMDTitle(markdown: string): string | null {
  const titleRegex = /#\s*(.+)/;
  const match = markdown.match(titleRegex);

  return match ? match[1].trim() : null;
}

export function removeMDTitle(markdown: string): string {
  const titleRegex = /#\s*(.+)/;
  return markdown.replace(titleRegex, "");
}
