import { CheckCircleIcon } from "@heroicons/react/24/outline";
import splitbee from "@splitbee/web";
import { loadStripe } from "@stripe/stripe-js";
import { useSession } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { ComponentPropsWithoutRef, FC, useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { ModalContext } from "../hooks/useModal";
import { useProfile } from "../hooks/useProfile";
import { capitalizeFirstLetter } from "../utils/string";
import Button from "./Button";
import ConfirmAction from "./ConfirmAction";
import Logo from "./Logo";
import Typography from "./Typography";

type PricingCardProps = {
  price: number;
  features: string[];
  plan:
    | "basic"
    | "pro"
    | "basic-yearly"
    | "pro-yearly"
    | "pro-plus"
    | "pro-plus-yearly";
} & ComponentPropsWithoutRef<"section">;

const PricingCard: FC<PricingCardProps> = ({
  features,
  price,
  plan,
  ...rest
}) => {
  const session = useSession();
  const router = useRouter();
  const { profile } = useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const posthog = usePostHog();
  const modal = useContext(ModalContext);
  const isUpgrade =
    profile?.plan != null && profile.plan != plan && plan == "pro";
  const isDowngrade =
    profile?.plan != null && profile.plan != plan && plan == "basic";

  // Coupon logic for experiment
  // const [coupon, setCoupon] = useLocalStorage("coupon", "");
  // useEffect(() => {
  //   if (coupon) {
  //     const couponCreatedAt = coupon.split(",")[1];

  //     const isExpired = isOlderThan(
  //       new Date(parseInt(couponCreatedAt)).toUTCString(),
  //       1
  //     );

  //     if (isExpired) localStorage.removeItem("coupon");
  //   }
  // }, [coupon]);

  const openStripeCheckoutPage = async (url: string) => {
    const stripe = await loadStripe(
      process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
    );

    if (!stripe) {
      console.error("Error loading Stripe");
      return;
    }
    const { error } = await stripe.redirectToCheckout({
      sessionId: url,
    });

    if (error) {
      console.error("Error redirecting to Stripe Checkout:", error);
    }
  };

  const getClientReferenceId = () => {
    // @ts-ignore
    return window.Rewardful && window.Rewardful.referral;
  };

  const openCheckout = async () => {
    setIsLoading(true);
    splitbee.track(`Plan Clicked (${plan})`);
    posthog?.capture(`Plan Clicked (${plan}`);
    const referral = getClientReferenceId();
    // console.log("referral", referral);
    // if (referral) {
    //   toast(`Referral ${referral}`);
    // }
    if (!session) return router.push(`/login?redirect=${router.asPath}`);
    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/billing/subscription`,
        {
          method: isDowngrade || isUpgrade ? "PATCH" : "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session?.access_token}`,
          },
          body: JSON.stringify({
            plan,
            referral: referral || undefined,
            // coupon:
            //   coupon && plan.includes("yearly")
            //     ? coupon.split(",")[0]
            //     : undefined,
          }),
        }
      );

      if (res.status == 401) return toast.error("Please login to continue");
      if (!res.ok)
        return toast.error(`Something went wrong (status ${res.status}`);
      const resJson = await res.json();
      openStripeCheckoutPage(resJson.session_id);
      // @ts-ignore
      ttq.track("InitiateCheckout", {
        value: plan.includes("pro") ? 79.0 : 29.0, // number. The order total. Example: 79.0.
        currency: "USD", // string. The currency of the order. Example: "USD".
        contents: [
          {
            content_id: plan,
            content_type: "product", // string. Either product or product_group.
            content_name: plan, // string. The name of the page or product. Example: "shirt".
            price: plan.includes("pro") ? 79.0 : 29.0, // number. The price of a single item. Example: 25.
          },
        ],
      });
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckout = async () => {
    if (isDowngrade || isUpgrade)
      return modal.open(
        <ConfirmAction
          title={`${
            isDowngrade ? "Downgrade" : "Upgrade"
          } your plan to ${capitalizeFirstLetter(plan)}?`}
          description="You can change your plan at any time."
          confirmLabel={
            isDowngrade ? "Downgrade plan" : isUpgrade ? "Upgrade plan" : ""
          }
          onConfirm={() => {
            openCheckout();
            modal.close();
          }}
          onCancel={() => modal.close()}
        />
      );

    openCheckout();
  };
  return (
    <section
      {...rest}
      className={`${rest.className} p-12 rounded-xl bg-slate-200`}
    >
      <div className="flex-col flex justify-center content-center items-center text-center">
        <div className="flex-1 flex justify-center content-center">
          <Logo />
          <Typography className="pl-1" variant="caption">
            {plan == "pro" || plan == "pro-yearly"
              ? "Pro"
              : plan.includes("plus")
              ? "Pro+"
              : ""}
          </Typography>
        </div>
        {/* <Typography variant="subtitle1" className="mt-8 text-gray-600">
          {description}
        </Typography> */}
        <Typography className="mt-8 flex items-baseline" variant="h3">
          <span
          // className={
          //   coupon && plan.includes("yearly") ? "line-through opacity-50" : ""
          // }
          >
            {"$" + price}
          </span>
          {/* {coupon && plan.includes("yearly") ? (
            <span className="text-purple-600">
              {"$" + (price * 0.7).toFixed(1)}
            </span>
          ) : (
            ""
          )} */}
          <Typography>/month</Typography>
        </Typography>

        <Button
          leading={
            profile?.plan == plan && (
              <CheckCircleIcon className="w-5 h-5 shrink-0" />
            )
          }
          variant={
            plan == "pro" || plan == "pro-yearly" ? "primary" : "secondary"
          }
          className="mt-8 w-full"
          disabled={profile?.plan == plan}
          onClick={handleCheckout}
          label={
            profile?.plan == plan
              ? "Current Plan"
              : isUpgrade
              ? "Upgrade"
              : isDowngrade
              ? "Downgrade"
              : "Get Started"
          }
          size="l"
          loading={isLoading}
        />
        <Typography className="mt-4 text-slate-500" variant="caption">
          Secured by Stripe
        </Typography>

        <ul className="flex-1 self-start flex flex-col mt-8 gap-4 w-full">
          {features.map((feature) => (
            <li key={feature} className="flex gap-2 items-center text-left">
              <CheckCircleIcon className="w-5 h-5 shrink-0" />
              <Typography variant="caption">{feature}</Typography>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default PricingCard;
