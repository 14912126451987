import { FaceSmileIcon } from "@heroicons/react/24/outline";
import splitbee from "@splitbee/web";
import { useFeatureFlagEnabled, usePostHog } from "posthog-js/react";
import { ComponentPropsWithoutRef, FC, useContext } from "react";
import { ModalContext } from "../hooks/useModal";
import { useProfile } from "../hooks/useProfile";
import Button from "./Button";
import NeedPaidPlan from "./NeedPaidPlan";
import Typography from "./Typography";

type FreeTrialBannerProps = {} & ComponentPropsWithoutRef<"div">;

const FreeTrialBanner: FC<FreeTrialBannerProps> = ({ ...rest }) => {
  const modal = useContext(ModalContext);
  const posthog = usePostHog();
  const { profile } = useProfile();
  const isCountryBlackListed = useFeatureFlagEnabled("is-country-black-list");

  if (!profile || profile.plan || isCountryBlackListed) return <></>;
  return (
    <div
      {...rest}
      className={`${rest.className} px-3 py-2 bg-slate-950 text-white border-b border-slate-800`}
    >
      <div className="flex items-center gap-4">
        <FaceSmileIcon className="w-6 h-6 text-slate-500" />
        <div>
          <Typography variant="subtitle3">
            {"You're in free trial mode"}
          </Typography>
          <Typography variant="caption" className="mt-0.5 text-slate-500">
            Some features are limited. Choose a plan to unlock all features.
          </Typography>
        </div>
        <Button
          size="m"
          variant="secondary"
          className="ml-auto"
          label="Upgrade"
          onClick={() => {
            posthog?.capture("Upgrade Button Clicked (free trial banner)");
            splitbee.track("Upgrade Button Clicked (free trial banner)");
            modal.open(<NeedPaidPlan plan="free" />, {
              className: "max-w-6xl",
            });
          }}
        />
      </div>
    </div>
  );
};

export default FreeTrialBanner;
