import { useProfile } from "@/hooks/useProfile";
import React from "react";

import { useEffect } from "react";
import OneSignal from "react-onesignal";

interface MainProps {
  children: React.ReactNode;
}

async function hash(string: string) {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, "0"))
    .join("");
  debugger;
  return hashHex;
}

export default function Main({ children }: MainProps) {
  const profile = useProfile();

  useEffect(() => {
    if (!profile.profile) return;
    try {
      // const logInOneSignal = async () => {
      //   try {
      //     await OneSignal.login(profile.profile!.id);
      //     // await OneSignal.User.addEmail(profile.profile!.email!);
      //   } catch (e) {
      //     console.warn(`OneSignal: ${e}`);
      //   }
      // };
      // logInOneSignal();

      // // @ts-ignore
      // document.tidioIdentify = {
      //   distinct_id: profile.profile?.id,
      //   email: profile.profile?.email, // Visitor email
      // };
      // // @ts-ignore
      // window.tidioChatApi.setContactProperties({
      //   subscription_status: profile.profile?.subscriptionStatus,
      //   plan: profile.profile?.plan,
      // });

      // @ts-ignore
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "o7y22sae",
        user_id: profile.profile?.id,
        // name: user.name, // Full name
        email: profile.profile.email, // Email address
        // created_at: user.createdAt,
        subscription_status: profile.profile?.subscriptionStatus,
        plan: profile.profile?.plan,
      });

      if (profile.profile?.email)
        // @ts-ignore
        ttq.identify({
          // SHA256 of the user's email address
          email: profile.profile?.email,
        });
    } catch (e) {
      console.warn(`Tidio: ${e}`);
    }
  }, [profile]);

  useEffect(() => {
    // @ts-ignore
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "o7y22sae",
    });
  }, []);

  return <>{children}</>;
}
