import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { ComponentPropsWithoutRef, FC, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import Button from "./Button";

const EDIT_TOOLS_NAV_ITEMS = [
  {
    label: "Video maker",
    href: "/tools/video-maker",
  },
  {
    label: "Video trimmer",
    href: "/tools/video-trimmer",
  },
];
const CAPTIONS_TOOLS_NAV_ITEMS = [
  {
    label: "Subtitle generator",
    href: "/tools/subtitle-generator",
  },
  {
    label: "Translate video",
    href: "/tools/translate-videos",
  },

  {
    label: "Transcribe podcast",
    href: "/tools/podcast-transcription",
  },
];

const CROP_TOOLS_NAV_ITEMS = [
  {
    label: "Crop video",
    href: "/tools/crop-video",
  },
  {
    label: "Video resizer",
    href: "/tools/resize-videos",
  },
];

type ToolsNavProps = {} & ComponentPropsWithoutRef<"div">;

const ToolsNav: FC<ToolsNavProps> = ({ ...rest }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div {...rest} className={`${rest.className} relative group`}>
      <div
        ref={ref}
        className={`bg-white rounded-2xl shadow-2xl p-1.5 flex-col w-48 border border-slate-200 z-50 absolute top-full mt-2 -left-1.5 ${
          open ? "" : "hidden"
        }`}
      >
        <ul>
          {EDIT_TOOLS_NAV_ITEMS.map((item) => (
            <li key={item.label}>
              <Link href={item.href}>
                <Button
                  onClick={() => {}}
                  label={item.label}
                  variant="tertiary"
                  className="w-full flex !justify-start hover:!bg-slate-100"
                  size="m"
                />
              </Link>
            </li>
          ))}
        </ul>
        <hr className="h-px bg-slate-200 border-transparent mx-4 rounded-full my-1.5" />
        <ul>
          {CROP_TOOLS_NAV_ITEMS.map((item) => (
            <li key={item.label}>
              <Link href={item.href}>
                <Button
                  onClick={() => {}}
                  label={item.label}
                  variant="tertiary"
                  className="w-full flex !justify-start hover:!bg-slate-100"
                  size="m"
                />
              </Link>
            </li>
          ))}
        </ul>
        <hr className="h-px bg-slate-200 border-transparent mx-4 rounded-full my-1.5" />
        <ul>
          {CAPTIONS_TOOLS_NAV_ITEMS.map((item) => (
            <li key={item.label}>
              <Link key={item.label} href={item.href}>
                <Button
                  onClick={() => {}}
                  label={item.label}
                  variant="tertiary"
                  className="w-full flex !justify-start hover:!bg-slate-100"
                  size="m"
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Button
        onClick={() => setOpen(true)}
        label="Tools"
        variant="tertiary"
        size="m"
        trailing={<ChevronDownIcon className="w-4 h-4 text-slate-500" />}
      />
    </div>
  );
};

export default ToolsNav;
