import router from "next/router";
import { createContext, useState } from "react";
import { ExportOptions } from "../components/ExportForm";

const apiOriginURL = process.env.NEXT_PUBLIC_API_URL as string;

export interface ExportItem {
  status: "idle" | "exporting" | "success" | "error";
  progress: number;
  error: string | null;
  estimatedTime: number | null;
  videoUrl: string | null;
  clipId: string | null;
}

interface ExportContextType {
  export: (totalFrames: number, exportOptions: ExportOptions) => void;
  get: (clipId: string) => ExportItem | undefined;
  exports: ExportItem[];
}

export const ExportContext = createContext<ExportContextType>({
  export: () => {},
  get: () => undefined,
  exports: [],
});

export const ExportProvider = ({ children }: { children: React.ReactNode }) => {
  const [exports, setExports] = useState<ExportItem[]>([]);

  const download = (fileBlob: Blob) => {
    // Create a URL object from the Blob
    const blobUrl = URL.createObjectURL(fileBlob);

    // Create an anchor element
    const link = document.createElement("a");
    link.href = blobUrl;
    // @ts-ignore
    link.download = fileBlob.name;

    // Append the anchor element to the document's body
    document.body.appendChild(link);

    // Trigger a click event on the anchor element to initiate the download
    link.click();

    // Remove the anchor element from the document's body after the download is initiated
    document.body.removeChild(link);

    // Release the reference to the Blob URL after the download is completed
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 100);
  };

  const downloadFile = (url: string, filename: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => download(blob));
  };

  const fakeProgress = (
    duration: number,
    rate: number = 24,
    maxProgress: number = 99
  ) => {
    // Calculate the time interval and increment per update
    const intervalTime = (duration * 1000) / (rate * maxProgress); // time between updates in milliseconds
    const increment = maxProgress / (rate * duration); // increment per update to reach maxProgress% in the specified duration

    const interval = setInterval(() => {
      setExports((prevExports) =>
        prevExports.map((exportItem) =>
          exportItem.status === "exporting"
            ? {
                ...exportItem,
                progress: Math.min(
                  exportItem.progress + increment,
                  maxProgress
                ),
              }
            : exportItem
        )
      );

      if (
        exports.find(
          (exportItem) =>
            exportItem.status === "exporting" &&
            exportItem.progress >= maxProgress
        )
      ) {
        clearInterval(interval);
      }
    }, intervalTime);
  };

  const exportVideo = async (
    estimatedTime: number,
    exportOptions: ExportOptions
  ) => {
    const clipId = router.query.variant_id as string;

    setExports((prevExports) => {
      const exportItem = prevExports.find(
        (exportItem) => exportItem.clipId === clipId
      );

      if (exportItem) {
        return prevExports.map((exportItem) =>
          exportItem.clipId === clipId
            ? {
                ...exportItem,
                status: "exporting",
                progress: 0,
                estimatedTime,
                error: null,
                videoUrl: null,
              }
            : exportItem
        );
      } else {
        return [
          ...prevExports,
          {
            status: "exporting",
            progress: 0,
            estimatedTime,
            error: null,
            videoUrl: null,
            clipId,
          },
        ];
      }
    });

    // // console.log("START EXPORT");

    fakeProgress(estimatedTime);

    try {
      const resJson = await fetch(
        `${apiOriginURL}/videos/${router.query.id}/clips/${router.query.variant_id}/export`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            options: exportOptions,
          }),
        }
      ).then((res) => res.json());

      setExports((prevExports) =>
        prevExports.map((exportItem) =>
          exportItem.clipId === clipId
            ? { ...exportItem, progress: 100, videoUrl: resJson.url }
            : exportItem
        )
      );

      downloadFile(resJson.url, `${clipId}.mp4`);
    } catch (error: any) {
      console.error(error);

      setExports((prevExports) =>
        prevExports.map((exportItem) =>
          exportItem.clipId === clipId
            ? { ...exportItem, status: "error", error: error.message }
            : exportItem
        )
      );
    } finally {
      setExports((prevExports) =>
        prevExports.map((exportItem) =>
          exportItem.clipId === clipId
            ? { ...exportItem, status: "success" }
            : exportItem
        )
      );
    }
  };

  const getExport = (clipId: string) => {
    return exports.find((exportItem) => exportItem.clipId === clipId);
  };

  return (
    <ExportContext.Provider
      value={{
        export: exportVideo,
        get: getExport,
        exports,
      }}
    >
      {children}
    </ExportContext.Provider>
  );
};
